import { COOKIES } from '~/constants'
import { ONE_HOUR_IN_SECONDS } from '~/constants/durations'

export default defineNuxtRouteMiddleware((to) => {
  const { status } = useAuth()
  const error = useError()
  // Return immediately if user is already authenticated or an error occurred
  if (status.value === 'authenticated' || error.value) {
    return
  }

  const runtimeConfig = useRuntimeConfig()

  // Store the path the user was trying to access
  useCookie(COOKIES.unauthorizedRedirect, {
    domain: runtimeConfig.public.cookieDomain,
    path: '/',
    maxAge: ONE_HOUR_IN_SECONDS,
  }).value = to.fullPath

  // Redirect to login page
  const localePath = useLocalePath()
  return navigateTo(localePath('index-signin'))
})
